
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";

html, body {
	margin: 0;
	padding: 0;
	border: 0;
	//font-size: 100%;
	//font: inherit;
	vertical-align: baseline;
}


.main {
	margin:8em 0 5em;
}
::-webkit-input-placeholder{
	color:#555 !important;
	font-size:1em !important;
}
.login {
	width: calc(304px + 6em);
	margin: 0 auto;
	background:#fff;
	padding: 3em;
	position: relative;
	border-radius: 5px;

	h1 {
		text-align: center;
		font-size: 2em;
		margin: 1em 0;
		color: #555;
		//font-family: 'PT Sans Narrow', sans-serif;

		&.logo {
			text-indent: -9999px;
			background: url('../images/telepol.jpg');
			background-size: cover;
			height: 74px;
		}
	}
}
.login-top {
	background: #127DA7;
	position: absolute;
	width: 70px;
	height: 70px;
	top: -42px;
	left: 162px;
	border-radius: 50px;
	text-align: center;
	font-size: 25px;
	color: #ffffff;

	.icon {
		position: absolute;
		top: 35px;
		left: 35px;
		margin-top: -15px;
		margin-left: -10px;
	}
}
input.new-input {
	font-size: 1.2em;
	/* Заради google recaptcha */
	width: 304px;
	padding: .6em .8em;
	outline: none;
	background: #eaeaea;
	border: none;
	border-radius: 5px;
	text-align: center;
}

input.new-submit,
a.new-submit,
button.new-submit {
	font-size: 1.5em;
	padding: .3em .3em;
	border: none;
	text-transform: capitalize;
	outline: none;
	border-radius: 5px;
	background: #127DA7;
	color: #fff;
	cursor: pointer;
	margin: 1.5em auto;
	display: inline-block;
}

a.new-submit:hover {
	text-decoration: none;
}

.login-bottom {
	input.new-submit,
	a.new-submit,
	button.new-submit {
		width: 45%;
		display: block;
	}
	p {
		font-size: 1em;
		text-align: center;
		margin-top: 2em;
		color: #555;
	}

	a {
		text-decoration:none;

		p:hover {
			color: #127DA7;
		}
	}
}

.margin {
	margin: 1.5em 0;
}
.footer {
	text-align: center;
	padding: 3em 0;

	p {
		font-size: .9em;
		color: #fff;
		margin: 0px;

		a {
			color: #fff;

			&:hover {
				color: #fff;

			}
		}
	}
}

/*responsive*/
@media(max-width:1440px){
	.login-top {
		left: 149px;
	}
}
@media(max-width:1366px){
	.login-top {
		left: 145px;
	}
}
@media(max-width:1280px){
	.login-top {
		left: 136px;
	}
}
@media(max-width:1080px){
	.login {
		h1 {
			font-size: 1.8em;
		}
	}
	.login-top {
		left: 136px;
	}
	.login-bottom p {
		font-size: .95em;
	}
}
@media (max-width: 991px){
	input.new-input {
		font-size: .95em;
	}

	input.new-submit {
		font-size: 1.4em;
	}

	.main {
		margin: 9em 0 5em;
	}
}
@media (max-width: 640px){
	input.new-input {
		font-size: .95em;
	}

	input.new-submit,
	a.new-submit,
	button.new-submit {
		font-size: 1.2em;
	}

	.login-bottom {
		input.new-submit,
		a.new-submit,
		button.new-submit {
			width: 40%;
		}
	}
}
@media (max-width: 480px){
	.login {
		padding: 2.5em;
	}
	.login-top {
		left: 126px;
	}
	input.new-submit {
		font-size: 1.3em;
		padding: .2em .2em;
	}
	.login-bottom p {
		font-size: .85em;
	}

	.login-bottom {
		input.new-submit,
		a.new-submit,
		button.new-submit {
			width: 91%;
		}
	}
}
@media (max-width: 320px){
	.login {
		padding: 1.5em;
	}
	.login-top {
		left: 92px;
	}
	.main {
		margin: 6em 0 3em;
	}
	.footer {
		text-align: center;
		padding: 1em 0;
	}
	.login-bottom p {
		font-size: .8em;
	}
}

/* General  */
.table thead > tr > th {
	background-color: #eee;
	border-bottom: 1px;
	text-align: center;
	font-size: 13px;
}
.table tbody > tr > td {
	font-size: 12px;
	text-align: left;
}
h3 {
	font-size:20px;
	font-weight: bold;
	text-align: center;
	border: 1px solid #ccc;
	padding-top: 10px;
	padding-bottom: 10px;
	letter-spacing: 2px;
}
h5 {
	font-size: 13px;
}

.text-align-right {
	text-align: right !important;
}
h3,h5 {
	margin:5px 5px;
}
.no-padding {padding:0 0;}
.well {
	border-radius: 2px 2px;
}
h2 {
	text-align: center;
	font-size: 20px;
	font-weight: bold;
}
fieldset.scheduler-border {
	border: 1px groove #ddd !important;
	padding: 0 1.4em 1.4em 1.4em !important;
	margin: 0 0 1.5em 0 !important;
	-webkit-box-shadow:  0 0 0 0 #000;
	box-shadow:  0px 0px 0px 0px #000;
}

legend.scheduler-border {
	font-size: 1.2em !important;
	font-weight: bold !important;
	text-align: left !important;
	width:auto;
	padding:0 10px;
	border-bottom:none;
}
.earn {
	color: white;
	background-color: #11bf8d;
}
.expense {
	color: white;
	background-color: #bd3b30;
}
.exp_emp {
	color: white;
	background-color: #d89110;
}
.total_info {
	color: white;
	background-color: #337ab7;
}
.total_info2 {
	color: white;
	background-color: #6dbbff;
}